import { Field } from 'formik'
import React from 'react'
import CurrencyInput from '../../helpers/CurrencyInput';

const InputCurrency = ({ attr, callChange }) => {
    return (
        <CurrencyInput
            id={attr.name}
            name={attr.name}
            onChange={callChange}
            currency="IDR"
            placeholder="Masukkan jumlah"
            className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
        />
    )
}

export default InputCurrency;