import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [pathURL, setPatURL] = useState(null);
  const url = window.location.origin;  
  const parsedUrl = new URL(url);
  const domain = parsedUrl.hostname;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/system/home?domain=${domain}`);
        setPatURL(response.data.path_url);
        console.log(response);
        
        
      } catch (err) {
        console.log(err);        
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [domain]);

  useEffect(() => {
    if (pathURL) {
      navigate(pathURL);
    }
  }, [pathURL])

  if (loading) return <p>Loading...</p>;


  return (
    <div>{domain}</div>
  )
}

export default Home;
