// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './project/pages/Activity/Auth';
import Dashboard from './project/pages/Dashboard';
import NotFoundPage from './project/pages/NotFoundPage';
import ProtectedRoute from './project/component/ProtectedRoute';
import Activity from './project/pages/Activity';
import AuthorizedActivity from './project/pages/Activity/Authorized';
import './App.css';
import Home from './website/Home';
import { AuthProvider } from './project/context/AuthContext';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/admin-login" element={<Auth />} />
                    
                    <Route path="/public/:link/:params?" element={<Activity />} />
                    <Route path="/activity/auth" element={<ProtectedRoute element={Auth} />} />
                    <Route path="/activity/:link/:params?" element={<ProtectedRoute element={AuthorizedActivity} />} />
                            
                    <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;