// src/components/DataTable.js
import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import ActionComponent from '../../pages/Activity/Component/ActionComponent';

const DataTable = ({ columns, elementClass, data, actions, moduleActions, enableCustom }) => {

  const handleAction = (rowData, callback) => {
    callback(rowData);
  };

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            {actions?.map((action, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    handleAction(row.original, action.callback)
                  }}
                  className={action.className}>
                  {action.child ?? action.title}
                </button>
              );
            })}

            {moduleActions?.map((action, index) => {
              return (
                <ActionComponent
                  key={index} action={action} triggerData={row.original} custom={enableCustom} />
              );
            })}
          </div>
        ),
      },
    ],
    [columns] // Add dependencies
  );

  // Use the useTable hook to create the table configuration
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: columnsWithActions,
    data,
  });

  return (
    <div className={elementClass}>
      <table {...getTableProps()} className="min-w-full bg-white border border-gray-200 p-2">
        <thead className="bg-gray-100">
          {headerGroups.map(headerGroup => {
            const { getHeaderGroupProps, headers } = headerGroup;
            return (
              <tr {...getHeaderGroupProps()} key={headerGroup.id}>
                {headers.map(column => {
                  const { getHeaderProps, render } = column;
                  return (
                    <th
                      {...getHeaderProps()}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      key={column.id}
                    >
                      {render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()} className="border-b border-gray-200 hover:bg-gray-50">
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    key={cell.column.id}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;