// src/components/DynamicComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ActionComponent from './ActionComponent';
import Modal from '../../../component/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { confirmDelete } from '../../../helpers/PromptUtils';

const DynamicComponent = ({ component, attribute, custom = false }) => {
    const navigate = useNavigate();

    const attributes = { ...attribute };
    const { id_module, component_id: id, component_parent: parent, component_element: element, children } = component;
    const [actions, setActions] = useState([]);

    const [loading, setLoading] = useState(true);

    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        fields: []
    });

    const arrayToObject = (array) => {
        return array.reduce((accumulator, current) => {
            if (current.key && current.value !== undefined) {
                accumulator[current.key] = current.value;
            }
            return accumulator;
        }, {});
    };

    const openModal = (type) => {
        let fields = [];
        let title = "";

        if (type === 'action') {
            title = "Action";
            console.log({ id_module, title });
        } else if (type === 'layout') {
            title = "Layout";
            fields = [{ column: 'element', column_type: 'text', input_type: 'text', value: '' }];
        } else {
            alert("Nothing");
            return;
        }

        setModalConfig({
            visible: true,
            title: title,
            fields: fields
        });
    };

    const closeModal = () => setModalConfig({ visible: false, title: '', fields: [] });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = {
                id_project: '1',
                id_module,
                id_parent: id,
                element: values.element,
            };

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/component`, formData);
            window.location.reload();
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const removeComponent = (id) => {
        const confirmed = confirmDelete('Are you sure you want to delete this component?');
        if (confirmed) {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/component/delete`, { id })
                .then(() => window.location.reload());
        }
    };

    useEffect(() => {
        const fetchActions = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/action?id_parent=${id}`);
                setActions(response.data.action);
            } catch (error) {
                console.error(`Error executing action for component ${id}:`, error);
            } finally {
                setLoading(false);
            }
        };

        fetchActions();
    }, [id]);

    if (loading) return <p>Loading...</p>;

    return (
        <div {...attributes}>
            {/* className={`flex ${custom ? 'border-dashed border-2 border-grey-200 p-2' : 'p-2 bg-grey'}`} */}
            {modalConfig.visible && <Modal title={modalConfig.title} fields={modalConfig.fields} closeModal={closeModal} submitModal={onSubmit} />}

            {children && children.length > 0 && (
                <div className='w-full flex'>
                    {children.map((child, index) => {
                        return <DynamicComponent key={index} component={child} custom={custom} attribute={arrayToObject(child.attributes)} />
                    })}
                </div>
            )}

            <div className={actions.length > 0 ? 'w-full' : 'w-auto'}>
                {custom && <b className='text-xs font-bold'>Layout {id} ({element})</b>}

                {actions.map((action, index) => (
                    <ActionComponent key={index} action={action} custom={custom} />
                ))}

                {custom && (
                    <div className='flex'>
                        {parent != 0 && (
                            <button onClick={() => removeComponent(id)} className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 m-2">
                                <FontAwesomeIcon icon={faTrashAlt} color="white" />
                            </button>
                        )}
                        <button onClick={() => openModal('layout')} className='text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700'>+ Layout</button>
                        <Link className="text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700" to={`/activity-component/${id_module}/${id}`}>+ Component</Link>
                        <button onClick={() => {
                            navigate(`/edit-activity-layout/${id_module}/${parent}/${id}`);
                        }} className='text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700'>Edit Layout</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DynamicComponent;