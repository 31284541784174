import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const Auth = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const url = window.location.origin;  
    const parsedUrl = new URL(url);
    const domain = parsedUrl.hostname;
    
    // const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        username: '', //imran
        password: '', //P@ssw0rd
    });

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/auth`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain : domain,
                username: values.username,
                password: values.password,
            }),
        });

        try {
            const token = await response.json();
            if (token) {
                login(token); // Menyimpan token dan mengatur status autentikasi
                navigate('/dashboard');
            } else {
                alert('Username atau Password salah');
            }            
        } catch (error) {
            alert('Username atau Password salah');
        }

    };

    // if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto bg-white shadow-lg rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className='flex-1'>
                                <div className="mb-4">
                                    <label htmlFor="username">Username</label>
                                    <Field
                                        name="username"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" />
                                    <ErrorMessage name="username" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password">Password</label>
                                    <Field
                                        name="password"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="password" />
                                    <ErrorMessage name="password" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Auth;
