import { Field } from 'formik'
import React from 'react'

const InputText = ({ attr }) => {
    return (
        <div className='flex-1 my-3 px-2'>
            <label htmlFor={attr.name}>{attr.placeholder}</label>
            <Field
                type={attr.type}
                name={attr.name}
                placeholder={attr.placeholder}
                className={attr.className}
            />
        </div>
    )
}

export default InputText;