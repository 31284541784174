// src/components/ProtectedRoute.js

import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../Layout/Sidebar';

const ProtectedRoute = ({ element: Component }) => {
    const { auth } = useAuth();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    return auth.isAuthenticated ? (
        <div className={`app-container sidebar-open`}>
            <Sidebar toggleSidebar={toggleSidebar} user={auth.user} />
            <div className="content">
                <Component />
            </div>
        </div>
    ) : (
        <Navigate to="/admin-login" replace />
    );
};

export default ProtectedRoute;
