import React, { useEffect, useState } from 'react';
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import SortableList, { SortableItem } from "react-easy-sort";
import ActionComponent from "../../pages/Activity/Component/ActionComponent";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { confirmModal } from '../../helpers/PromptUtils';

const FormComponent = ({ elements, elementClass, request, enableCustom, callSort }) => {
    const navigate = useNavigate();
    const { params } = useParams();

    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState(Yup.object());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_BASE_URL}api/dashboard/request?url=${request.url}&id=${params}`
                });

                const data = response?.data?.result || {};

                // Set the initial values based on the API response
                const newInitialValues = {};

                elements.forEach(input => {
                    const attributes = input.action_attribute;
                    const nameField = attributes.find(attr => attr.key === 'name');
                    const typeField = attributes.find(attr => attr.key === 'type');

                    if (nameField) {
                        // Assign initial values based on the API response or default to empty string
                        newInitialValues[nameField.value] = data[nameField.value] || '';
                    }
                });

                setInitialValues(newInitialValues);

                // Dynamically create validation schema based on element types
                const schemaObject = {};

                elements.forEach(input => {
                    const attributes = input.action_attribute;
                    const nameField = attributes.find(attr => attr.key === 'name');
                    const typeField = attributes.find(attr => attr.key === 'type');
                    const labelField = attributes.find(attr => attr.key === 'placeholder');

                    if (nameField && typeField && labelField) {
                        let yupType;
                        switch (typeField.value) {
                            case 'date':
                                yupType = Yup.date();
                                break;
                            case 'number':
                                yupType = Yup.number();
                                break;
                            default:
                                yupType = Yup.string(); // default type
                        }
                        schemaObject[nameField.value] = yupType.required(`${labelField.value} wajib diisi`);
                    }
                });

                setValidationSchema(Yup.object().shape(schemaObject));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [request, params, elements]); // Dependencies: request, params, elements

    const onSubmitComponent = async (values, { setSubmitting }) => {
        values['url'] = request.url;
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/request`, values);
            const confirmed = confirmModal("Simpan Data Berhasil, Kembali ke Halaman sebelumnya?");
            if (confirmed) {
                navigate(-1); // Go back to previous page
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form: ' + error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={elementClass}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitComponent}
                enableReinitialize // Ensures the form reinitializes with new data if values change
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <SortableList
                            allowDrag={enableCustom}
                            onSortEnd={callSort}
                            className="list"
                            draggedItemClassName="dragged"
                        >
                            {elements.map((action, index) => {
                                const nameAttribute = action.action_attribute.find(attr => attr.key === 'name');
                                const labelAttribute = action.action_attribute.find(attr => attr.key === 'placeholder');

                                return (
                                    <SortableItem key={action.id} index={index}>
                                        <div style={{ cursor: 'pointer' }}>
                                            <ActionComponent
                                                callChange={(value) => {
                                                    setFieldValue(nameAttribute.value, value);
                                                }}
                                                key={index}
                                                action={action}
                                                custom={enableCustom}
                                            />
                                        </div>
                                    </SortableItem>
                                );
                            })}
                        </SortableList>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FormComponent;