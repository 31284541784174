import { Field } from 'formik'
import React from 'react'

const InputOption = ({ attr, option }) => {
    let options = [];
    if (option.length > 0) {
        options = option.map(item => ({
            id: item.id,
            name: item.name
        }));
    } else {
        options = attr.option.split(',').map(item => ({
            name: item.trim() 
        }));
    }
    

    return (
        <div className='flex-1 my-3 px-2'>
            <label htmlFor={attr.name}>{attr.placeholder}</label>
            <Field as="select" name={attr.name} className={attr.className}>
                <option value="">{attr.placeholder}</option>            
                {options && options.map((option, index) => (                
                    <option key={index} value={option.name}>
                        {option.name}
                    </option>
                ))}
            </Field>
        </div>
    );
}

export default InputOption;