import React, { useEffect, useState } from 'react';
import DataTable from "./Table/DataTable";
import Grid from "./Grid/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import List from "./List/List";
import { ErrorMessage, Field} from "formik";
import { Link } from "react-router-dom";
import axios from "axios";

import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { arrayMoveImmutable } from 'array-move';
import InputText from './Input/InputText';
import InputCurrency from './Input/InputCurrency';
import InputOption from './Input/InputOption';
import FormComponent from './Form/Form';
import QRCode from 'react-qr-code';
import InputTextarea from './Input/InputTextarea';

//form (action remove, action edit) [attribute, request]
//input (action remove, action edit), [type, attribute, callChange]
//button (action remove, action edit), [type, attribute, request] 
//link (action remove, action edit) [attribute]

const RenderComponent = ({ 
    component, 
    type, 
    attribute, 
    actionRemove, 
    actionEdit, 
    actionRequest, 
    callRequest, 
    enableCustom, 
    callChange }) => {
    const { id_module, component_id: id, component_request: request } = component;
    
    const [actions, setActions] = useState([]);
    const attributes = { ...attribute };
    
    const Container = ({ label, content }) => {
        if (enableCustom) {
            return <div className="card">
                <div className="rounded bg-gray-100 border-b border-gray-200 flex p-2">
                        <h6 className="text-xs font-semibold text-gray-900 flex-1">{label}</h6>
                        <button
                            className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1"
                            onClick={() => actionRemove(component)}>
                            <FontAwesomeIcon icon={faTrashAlt} color="white" />
                        </button>
                        <button
                            data-tip="This is a tooltip"
                            className="text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1"
                            onClick={() => actionEdit(component)}>
                            <FontAwesomeIcon icon={faEdit} color="white" />
                        </button>

                        {actionRequest ? (
                            <button
                                data-tip="This is a tooltip"
                                className="text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2 mx-1"
                                onClick={() => actionRequest && actionRequest(component)}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} color="white" />
                            </button>
                        ) : null}


                        {enableCustom == true && (type == 'form' || type == 'table') && (
                            <Link
                                data-tip="This is a tooltip"
                                className="text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2 mx-1"
                                to={`/activity-component/${id_module}/${id}`}>
                                <FontAwesomeIcon icon={faFolderPlus} color="white" />
                            </Link>
                        )}
                </div>{content}
            </div> ;           
        }
        return content;
    }

    useEffect(() => {
        const fetchActions = async () => {
            if (id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/action?id_parent=${id}`);

                    setActions(response.data.action);
                } catch (error) {
                    console.error(`Error executing action for component ${id}:`, error);
                }
            }
        };

        fetchActions();
    }, [id])

    useEffect(() => {
        const sortActions = async () => {
            try {
                const ids = actions.map(item => item.id);
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/action/sort`, {
                    "component_sort": ids
                });
            } catch (error) {
                console.error(`Error executing action for component ${id}:`, error);
            }
        }

        if (actions.length > 0 && enableCustom) {
            sortActions();
        }
    }, [actions]);


    switch (type) {
        case 'form':
            const onSortEnd = (oldIndex, newIndex) => {
                setActions((array) => arrayMoveImmutable(array, oldIndex, newIndex));
            };

            return (
                <Container 
                    label={component?.title ?? "Form"}                    
                    content={ 
                        <FormComponent 
                            callSort={onSortEnd} 
                            elements={actions} 
                            elementClass={attributes.className}                            
                            request={request} 
                            enableCustom={enableCustom} />
                    } />
            );
        case 'button':
            if (attributes.type == "submit") {                                
                return (
                    <Container 
                        label={component?.title ?? "Button Submit"} 
                        content={ 
                            <button {...attributes}>{attribute.child}</button>
                        } />
                );
            } else {
                return (
                    <Container 
                        label={component?.title ?? "Button"} 
                        content={
                            <button {...attributes} onClick={callRequest}>{attribute.child}</button>
                        } />
                );
            }
        case 'linktable':
                return (
                    <Container 
                        label={component?.title ?? "Link Table"} 
                        content={
                            <button onClick={callRequest} className={attributes.className}>{attribute.child}</button>
                        } />
                );
        case 'link':
            return (
                <Container 
                    label={component?.title ?? "Link"} 
                    content={
                        <Link {...attributes}>{attribute.child}</Link>
                    } />
            );
        case 'input':
            const inputField = (
                <span>
                    {(() => {
                        switch (attributes.type) {
                            case 'currency_idr':
                                return <InputCurrency attr={attributes} callChange={callChange} />;
                            case 'textarea':
                                return <InputTextarea attr={attributes} />;
                            case 'select':
                                return <InputOption attr={attributes} option={component.items}/>
                            default:
                                return <InputText attr={attributes} />;
                        }
                    })()}

                    <ErrorMessage
                        name={attributes.name}
                        component="p"
                        className="text-red-500 text-xs mt-1"
                    />
                </span>
            );
            if (enableCustom) {                
                return <Container label={component?.title ?? "Input"} content={inputField} />;
            }
            return inputField;
        case 'text':
            return (
                <Container 
                    label={component?.title ?? "Link"} 
                    content={
                        <p {...attributes}>{attribute.child}</p>
                    } />
            );
        case 'header':
            return <header>{component}</header>;
        case 'footer':
            return <footer style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
            }}>{component}</footer>;
        case 'card':
            return (
                <span className="card">
                    {component}
                </span>
            );
        case 'section':
            return (
                <section>{component}</section>
            );
        case 'grid':
            return (
                <Container 
                    label={component?.title ?? "Grid"} 
                    content={
                        <Grid items={component.items} columns={attribute.columns} />
                    }/>
            );
        case 'table':
            return (
                <Container 
                    label={component?.title ?? "Table"}
                    content={
                        <DataTable
                            data={component.items}
                            elementClass={attributes.className}
                            columns={component.columns}
                            moduleActions={actions}
                            enableCustom={enableCustom}/>
                    }/>
            );
        case 'list':
            return (
                <Container 
                    label={component?.title ?? "List"} 
                    content={
                        <List items={component.items} />
                    }/>
            )
        case 'qrcode':
                return (
                    <Container 
                        label={component?.title ?? "QR-Code"} 
                        content={
                            <div  {...attributes} style={{ padding:'30px', maxWidth: 256, width: "100%" }}>
                                <p>{attribute?.child ?? ""}</p>
                                <QRCode
                                    size={512}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={attribute?.child ?? ""}
                                    viewBox={`0 0 512 512`}
                                />
                            </div>
                        }/>
                )
        default:
            return null;
    }
};

export default RenderComponent;